// assets
import NavigationOutlinedIcon from '@mui/icons-material/NavigationOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TuneIcon from '@mui/icons-material/Tune';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const isElectron = navigator.userAgent.includes('Electron');

const icons = {
  NavigationOutlinedIcon: NavigationOutlinedIcon,
  HomeOutlinedIcon: HomeOutlinedIcon,
  ChromeReaderModeOutlinedIcon: ChromeReaderModeOutlinedIcon,
  HelpOutlineOutlinedIcon: HelpOutlineOutlinedIcon,
  SecurityOutlinedIcon: SecurityOutlinedIcon,
  AccountTreeOutlinedIcon: AccountTreeOutlinedIcon,
  BlockOutlinedIcon: BlockOutlinedIcon,
  AppsOutlinedIcon: AppsOutlinedIcon,
  ContactSupportOutlinedIcon: ContactSupportOutlinedIcon,
  WhatsAppIcon: WhatsAppIcon,
  SettingsIcon: SettingsIcon,
  AssessmentIcon: AssessmentIcon,
  TuneIcon: TuneIcon,
  AddBusinessIcon: AddBusinessIcon,
  AddIcCallIcon: AddIcCallIcon,
  HelpOutlineIcon: HelpOutlineIcon,
  EventAvailableIcon: EventAvailableIcon,
};

// eslint-disable-next-line
export default {
  items: [
    {
      id: 'navigation',
      title: 'Painel administrativo',
      caption: '',
      type: 'group',
      icon: icons['NavigationOutlinedIcon'],
      children: [
        {
          id: 'dashboard-page',
          title: 'Dashboard',
          type: 'item',
          icon: icons['HomeOutlinedIcon'],
          url: '/dashboard'
        },
        {
          id: 'call-center-page',
          title: 'Central de Atendimento',
          type: 'item',
          url: '/call-center',
          icon: icons['AddIcCallIcon'],
        },
        {
          id: 'whatsapp-page',
          title: 'Auto Atendimento',
          type: 'item',
          url: '#',
          icon: icons['WhatsAppIcon'],
          disabled: !isElectron
        },
        {
          id: 'schedule-professional-page',
          title: 'Agenda',
          type: 'item',
          url: '/schedule',
          icon: icons['EventAvailableIcon'],
        }
      ]
    },
    {
      id: 'pages',
      title: 'Páginas',
      caption: '',
      type: 'group',
      icon: icons['NavigationOutlinedIcon'],
      children: [
        {
          id: 'personalization-page',
          title: 'Personalização',
          type: 'item',
          url: '/personalization',
          icon: icons['TuneIcon']
        },
        {
          id: 'settings-page',
          title: 'Configurações',
          type: 'collapse',
          icon: icons['SettingsIcon'],
          children: [
            {
              id: 'settings-schedules-page',
              title: 'Horários',
              type: 'item',
              url: '/settings/schedules',
              target: false
            },
            {
              id: 'settings-payments-page',
              title: 'Pagamento',
              type: 'item',
              url: '/settings/payments',
              target: false,
              disabled: false
            }
          ]
        },
        {
          id: 'registrations-page',
          title: 'Cadastro',
          type: 'collapse',
          icon: icons['AddBusinessIcon'],
          children: [
            {
              id: 'registrations-coupons-page',
              title: 'Cupons',
              type: 'item',
              url: '/registrations/coupons',
              target: false,
              disabled: true
            },
            {
              id: 'registrations-products-page',
              title: 'Produtos',
              type: 'item',
              url: '/registrations/products',
              target: false
            },
            {
              id: 'registrations-professionals-page',
              title: 'Profissionais',
              type: 'item',
              url: '/registrations/professionals',
              target: false
            },
            {
              id: 'registrations-services-page',
              title: 'Serviços',
              type: 'item',
              url: '/registrations/services',
              target: false
            },
            {
              id: 'registrations-customers-page',
              title: 'Clientes',
              type: 'item',
              url: '/registrations/customers',
              target: false
            },
            {
              id: 'registrations-users-page',
              title: 'Usuários',
              type: 'item',
              url: '/registrations/users',
              target: false
            },
          ]
        },
        {
          id: 'reports-page',
          title: 'Relatórios',
          type: 'collapse',
          icon: icons['AssessmentIcon'],
          children: [
            {
              id: 'reports-ratings-page',
              title: 'Avaliações',
              type: 'item',
              url: '/reports/ratings',
              target: false,
              disabled: true
            },
            {
              id: 'reports-attendance-page',
              title: 'Atendimentos',
              type: 'item',
              url: '/reports/attendance',
              target: false
            },
            {
              id: 'reports-clients-page',
              title: 'Clientes',
              type: 'item',
              url: '/reports/clients',
              target: false
            },
            {
              id: 'reports-coupons-page',
              title: 'Cupons',
              type: 'item',
              url: '/reports/coupons',
              target: false,
              disabled: true
            },
            {
              id: 'reports-products-page',
              title: 'Produtos',
              type: 'item',
              url: '/reports/products',
              target: false,
            },
            {
              id: 'reports-professionals-page',
              title: 'Profissionais',
              type: 'item',
              url: '/reports/professionals',
              target: false,
            },
            {
              id: 'reports-services-page',
              title: 'Serviços',
              type: 'item',
              url: '/reports/services',
              target: false
            }
          ]
        }
      ]
    },
    {
      id: 'support',
      title: 'Suporte',
      type: 'group',
      icon: icons['ContactSupportOutlinedIcon'],
      children: [
        {
          id: 'disabled-menu',
          title: 'Central de Ajuda',
          type: 'item',
          url: 'https://evanescent-equipment-0dc.notion.site/Central-de-Artigos-18d3d6bc8d72800f9ca0d1a4ea8ac975',
          icon: icons['HelpOutlineIcon'],
          disabled: false,
          target: true
        }
      ]
    }
  ]
};
